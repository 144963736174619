////////////////////////////// CTA ////////////////////////////// 

.btn-signup {
    position: absolute;
    z-index: 999 !important;
    padding: 15px 25px;
    color: white;
}

.btn-signup-2 {
    padding: 15px 25px;
    color: white;
}

.btn-signup h5, .btn-signup-2 h5 {
    font-size: 1.5rem;
}

#section-cta {
    background-color: $secondary !important;
    color: $primary
}

.btn-signup-2:hover {
    color: $tertiary !important;
}

#section-cta > p {
    color: $primary-light;
}

// contact email
#section-cta > p > a{
    color: $primary;
    &:hover {
        color: $white-soft;
    }
}

// CTA title
#section-cta h2 {
    color: $primary;
}
