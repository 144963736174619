@import "/node_modules/bootstrap/scss/bootstrap";


$theme-colors: (

    "primary": #0F2140,
    "secondary": #ff4162,
    "info": #488eca

);

// Theme colors
$primary: #0F2140;
$primary-light: #0f2140b2;
$secondary: #ff4162;
$tertiary: #488eca;
$quaternary: #05F2F2;
$white-soft: rgba(255, 255, 255, 0.925);


// Navbar
$navbar-toggler-border-radius: 0px;

// Accordion (Home - FAQ)
$accordion-border-width: 0px;

$accordion-color: white;
$accordion-bg: $primary-light;

$accordion-button-bg: $primary;
$accordion-button-active-bg: $primary;

$accordion-button-color: white;
$accordion-button-active-color: white;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$secondary}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$secondary}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");


