////////////////////////////// FOOTER ////////////////////////////// 

#section-footer {
    font-size: 0.8rem;
    background-color: $primary;
}

#footer-logos {
    background-color: white;
    padding: 5px 10px;
}

.footer-logo {
    max-height: 25px;
    padding-right: 5px;
}

.footer-socmed-icon {
    margin: 5px;
    color: $primary;
}

.footer-text {
    color: $primary;
}

@include media-breakpoint-up(sm) { 
    .footer-logo {
        max-height: 40px;
        padding-right: 10px;
        margin: 5px;
    }
    
    .footer-socmed-icon {
        margin-right: 15px;
        color: $primary;
    }
}