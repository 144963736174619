@import "main";
@import "calltoaction.scss";

////////////////////////////// LANDING SECTION ////////////////////////////// 

#landing-text h2 {
    font-weight: 600;
}

.landing-deco {
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
}

.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

#landing {
    position: relative;
}

#top {
    height: 100vh !important;
}

.event-info {
    transition: all 0.2s ease-out;
    width: 70vw;
}

.event-info:hover {
    color: $tertiary;
}

#pic-arrow-graphics {
    max-width: 60vw;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px !important;
}

#pic-arrow-graphics:hover {
    margin-right: -50px;
}

#landing-text {
    padding: 0px 50px;
    font-family: 'Nunito', sans-serif;
    z-index: 100;
}

.video {
    z-index: 3;
}

////////////////////////////// OVERVIEW (WHAT IS V HACK) ////////////////////////////// 

#section-whatisvhack {
    background-color: #00060C;
    // background-image: url("../assets/whatisvhack-withdarkoverlay.png");
    width: 100%;
}

#section-whatisvhack h3, #section-whatisvhack h5, #section-whatisvhack h6 {
    text-shadow: 5px 5px 5px #000000;
}

#section-vhack-benefit {
    background-color: $secondary;
}

////////////////////////////// PARTNER UNIVERSITIES ////////////////////////////// 

#partner-universities-compilation {
    width: 80vh !important;
}

#partner-universities-graphic {
    width: 60vh !important;
}

.vhack-benefit {
    width: 200px;
    margin-bottom: 20px;
    transition: all .2s ease-in-out;
}

.vhack-benefit:hover {
    transform: scale(1.1) !important;
 }

.vhack-benefit div {
    color: $primary;
    font-size: 0.9rem;
}


// .btn:hover {
//     color: $primary !important;
// }


#section-event-partneruniversities, #section-sponsors {
    background-color: $white-soft;
}

#section-event-partneruniversities {
    color: $primary;
}

.dummy-block {
    width: 100%;
    height: 300px;
    background-color: grey;
}

////////////////////////////// YOUR V HACK JOURNEY ////////////////////////////// 

#journey-graphic {
    width: 119px;
    height: 105px;
}

#timeline-blocks {
    padding: 0px 20px;
}

.timeline-block {
    margin-top: 50px;
}

.timeline-circle {
    width: 60px;
    height: 60px;
    background-color: $secondary;
    min-width: 60px !important;
    max-width: 60px !important;
}

.timeline-circle .fas {
    font-size: 1.3rem;
    color: $primary;
}

.timeline-desc {
    font-size: small;
}


////////////////////////////// FAQ

#section-faq {
    padding: 20px;
    background-color: $tertiary;
}

#accordion-faq {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.accordion-body {
    padding: 20px;
    p {
        text-align: left;
        margin: 0;
        color: white;
    }
}

.accordion-body > p > a {
    color: white !important;
    font-weight: bold;
}

////////////////////////////// MEET THE MENTORS ////////////////////////////// 
#section-meetthementors {
    background-color: $primary;
}

.mentor-card {
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    margin-bottom: 20px;
    background-color: $secondary;
    color: $primary;
}

.mentor-card:hover {
    box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    border: 2px solid white;
}

.mentor-card-text {
    font-size: 0.8rem;
    margin: 0px;
}

.mentor-card-text a {
    font-weight: bold;
    color: $primary;
}

.mentor-card-text a:hover {
    color: $primary-light;
}

.mentor-image {
    object-fit: cover;
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.mentor-badge {
    background-color: $primary;
}

////////////////////////////// MEET THE JUDGES ////////////////////////////// 

#section-meetthejudges {
    background-color: $tertiary;
}

#section-meetthejudges h3, #section-meetthejudges h6 {
    color: $primary;
}

#section-meetthejudges h5 {
    font-size: 1rem
}

#section-meetthejudges p {
    font-size: 0.7rem
}

.judge-card {
    background-color: $primary;
    transition: all 0.2s ease-in-out;
}

.judge-card:hover {
    box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    border: 2px solid white;
}

.judge-image {
    object-fit: cover;
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.judge-position-text {
    font-size: small;
}

.judge-company-text {
    font-size: x-small;
}

.judge-badge {
    background-color: $secondary;
}

.badge:hover {
    opacity: 0.9;
}

////////////////////////////// SPONSORS ////////////////////////////// 
#section-sponsors {
    color: $primary;
}

.sponsor-type h3 {
    margin-bottom: 0px;
    margin-top: 20px;
}

.sponsor-title-line {
    height: 3px !important;
    width: 20%;
    color: $primary;
    margin: auto;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.sponsor-type:hover .sponsor-title-line {
    width: 60%;
}

.sponsor-logo {
    width: 200px;
    height: 200px;
    border: 1px solid rgba(128, 128, 128, 0.466);
}

.mail {
    background-color: white;
    padding: 20px;
    width: 80vw;
    color: $primary;
}

.mail > div > h4 {
    font-size: 1rem;
}

.mail-icon {
    font-size: 1.5rem;
}


@include media-breakpoint-up(sm) { 

    #landing {
        padding: 20px 80px;
        padding-top: 50px;
    }

    #pic-arrow-graphics {
        max-width: 40vw;
    }

    .carousel-control-prev {
        margin-left: -80px !important;
    }
      
    .carousel-control-next {
        margin-right: -80px !important;
    }

    #section-sponsor {
        padding: 50px;
    }

    #journey-graphic {
        width: 209px;
        height: 185px;
    }    

    .timeline-block {
        width: 60%;
        margin-top: auto;
    }

    .timeline-circle {
        width: 100px;
        height: 100px;
        min-width: 100px !important;
        max-width: 100px !important;
    }
    
    .timeline-circle .fas {
        font-size: 2.3rem;
    }

    #accordion-faq {
        width: 60%;
    }

    .mentor-card {
        margin-left: 20px;
    }
    
    .sponsor-title-line {
        width: 10%;
    }
    
    .sponsor-type:hover .sponsor-title-line {
        width: 20%;
    }

    .mail {
        width: 50vw;
    }

    .mail-icon {
        font-size: 3rem;
    }

    .mail > div > h4 {
        font-size: 1.5rem;
    }

}

@include media-breakpoint-between(lg, xxl) { 
    .landing-deco {
        width: 45vw;
        margin-bottom: -10vw !important;
    }
    
}

@include media-breakpoint-up(xl) { 

    #landing-text h2 {
        font-size: 3rem;
    }

    #landing-text h6 {
        font-size: 1.5rem;
    }

    #pic-arrow-graphics {
        max-width: 30vw;
    }

    .landing-deco {
        width: 55vw;
        margin-bottom: -5vw;
    }

    #sponsor-banners {
        width: 50%;
    }
}

@include media-breakpoint-up(xxl) { 

    #landing-text {
        padding-left: 100px;
    }

    #landing-text h2 {
        font-size: 4rem;
    }

    #landing-text h6 {
        font-size: 2rem;
    }

    #pic-arrow-graphics {
        max-width: 30vw;
    }
}

@include media-breakpoint-down(lg) { 
    
    // fontawesome icons
    .dropdown-item .fas, .fa-users {
        font-size: 1.5rem;
    }

    .mentor-image {
        margin-top: 20px;
    }

    .mentor-badges {
        display: flex;
        justify-content: center;
    }
    
    .mentor-badge {
        margin: 2px;
    }

}


