@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "navbar";
@import "footer.scss";

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
    min-height: 100vh;
}

body {
    font-family: 'Inter', sans-serif;
    background-color: #0F2140;
    background: linear-gradient(#0F2140, #1A375B) no-repeat;
    color: white;
}

hr {
    color: black;
}

.modal {
    h5, p {
        color: black;
    }
}

// remove underline from links
a {
    text-decoration: none;
    color: $primary;
    transition: all 0.2s;
    &:hover {
        opacity: 1;
        color: $secondary;
    }
}

.theme-b {
    color: $primary;
}

.theme-r {
    color: $secondary;
}

.landing-top-page {
    padding-top: 100px !important;
}