#vhack-logo {
     transition: all .2s ease-in-out;
}

#vhack-logo:hover {
    transform: scale(1.1);
}

.navbar.scrolled {
    background-color: $primary !important;
    transition: background-color 200ms linear;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.navbar {
    background-color: $primary !important;
    z-index: 999;
}

.navbar-nav {
    column-gap: 1em
}

.nav-link, .navbar-brand span {
    transition: all .2s ease-in-out;
    color: white;
    a:hover {
        color: $primary !important;
    }
}

.nav-link:hover {
    border-bottom: 2px solid $secondary;
}

.navbar-brand span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
}

.nav-link:hover, .navbar-brand span:hover, .nav-link:focus, .navbar-brand span:focus {
    color: $secondary;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.dropdown-menu {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 5px 0px 5px 0px;
}

// fontawesome icons
.dropdown-item .fas, .fa-users {
    margin-right: 10px;
}

.dropdown-item {
    font-size: 0.95em;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
}

.dropdown-item:hover {
    background-color: $secondary;
    
}

@include media-breakpoint-up(lg) { 
    .navbar.scrolled {
        opacity: 0.8 !important;
    }
}